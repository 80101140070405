import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { LoaderService } from './loader.service';
import { PwCacheStatus } from '@pwiz/infra/cache/ts';

@Component({
  selector: 'pw-loader-provider',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: LoaderService, useClass: LoaderService }],
  host: {
    class: 'pw-loader-provider pw-display-contents',
  },
  template: `<ng-content />`,
})
export class LoaderProviderComponentComponent {
  status = input.required<PwCacheStatus>();

  constructor() {
    inject(LoaderService).$status = this.status;
  }
}
